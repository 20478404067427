<template>
  <div class="batteryDetail flex">
    <div class="fixed top-0 min-w-full bg-gray-600 md:pt-6 pb-48 pt-12" />
    <div class="flex flex-wrap justify-between px-8 md:px-14 w-full">
      <div class="w-full lg:w-full">
        <SlideUp>
          <ListTable
            :tableName="$t('Invoice')"
            :headers="headers"
            :tableData="$store.state.invoices.invoices"
            :loading="$store.state.invoices.loading"
            createName="Invoice"
          />
        </SlideUp>
      </div>
    </div>
  </div>
</template>

<script>
import ListTable from "@/components/ListTable.vue";
import SlideUp from "@/components/transitions/SlideUp.vue";
export default {
  name: "Invoices",
  components: {
    ListTable,
    SlideUp
  },
  data() {
    return {
      headers: [
        {
          name: "#",
          key: "id",
          type: "hidden",
          linkInfo: { name: "Invoice", routeKey: "id", varKey: "id" }
        },
        {
          name: "status",
          key: "status",
          type: "tag",
          paid: "green",
          unpaid: "rose",
          rejected: "blue"
        },
        { name: "supplier", key: "send_to_supplier.name", type: "" },
        { name: "date sent", key: "date", type: "datetime" },
        { name: "due date", key: "due_date", type: "datetime" },
        { name: "created by", key: "created_user.name", type: "" }
      ]
    };
  },
  created() {
    this.$store.dispatch("invoices/getAllInvoices");
  }
};
</script>
